import axios from "axios";

export const insertValues = async (object: Record<string, string>) => {
  const sheetServiceURL = process.env.REACT_APP_GOOGLE_SPREADSHEETS_WEBB_APP;
  if (!sheetServiceURL) {
    return {
      status: "error",
      message: "Oops! An error occurred while submitting the RSVP"
    };
  }

  try {
    await axios.post(sheetServiceURL, object, {
      headers: {
        "Content-Type": "text/plain"
      }
    });

    return {
      status: "success",
      message: "RSVP sent successfully!"
    };
  } catch (error) {
    console.error(error);

    return {
      status: "error",
      message: "Oops! An error occurred while submitting the RSVP"
    };
  }
};
