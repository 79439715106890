interface LoadingSpinnerProps {
  color: string;
}

const LoadingSpinner = ({ color }: LoadingSpinnerProps) => {
  return (
    <div
      className="lds-ring"
      style={{
        color
      }}
    >
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
};

export default LoadingSpinner;
