import { create } from "zustand";

import { aamwSlice } from "./slices";
import { AAMWStore } from "./types";

const useAAMWStore = create<AAMWStore>()((...a) => ({
  ...aamwSlice(...a)
}));

export default useAAMWStore;
