import { useEffect, useState } from "react";

const HIDE_HEADER_THRESHOLD = 20;
const SHOW_HEADER_THRESHOLD = 100;

const useHeaderScroll = () => {
  const [currentScrollY, setCurrentScrollY] = useState(0);
  const [lowestScrollSinceVisible, setLowestScrollSinceVisible] = useState(0);
  const [isHeaderVisible, setIsHeaderVisible] = useState(true);

  const isHeaderAtTop = currentScrollY === 0;
  const isHeaderTransparent = currentScrollY < 100;

  useEffect(() => {
    if (isHeaderAtTop) {
      setIsHeaderVisible(true);
      return;
    }

    if (isHeaderVisible) {
      if (currentScrollY > lowestScrollSinceVisible + HIDE_HEADER_THRESHOLD) {
        setLowestScrollSinceVisible(currentScrollY);
        setIsHeaderVisible(false);
      } else {
        if (currentScrollY < lowestScrollSinceVisible - HIDE_HEADER_THRESHOLD) {
          setLowestScrollSinceVisible(currentScrollY);
        }
      }
    } else {
      if (
        currentScrollY === 0 ||
        currentScrollY < lowestScrollSinceVisible - SHOW_HEADER_THRESHOLD
      ) {
        setIsHeaderVisible(true);
        setLowestScrollSinceVisible(currentScrollY);
      } else {
        if (currentScrollY > lowestScrollSinceVisible) {
          setLowestScrollSinceVisible(currentScrollY);
        }
      }
    }
  }, [currentScrollY]);

  const handleScroll = (e: Event) => {
    const el = e.target as HTMLElement;
    setCurrentScrollY(el.scrollTop);
  };

  useEffect(() => {
    const main = document.querySelector(".main");
    if (!main) return;
    main.addEventListener("scroll", handleScroll);
    return () => {
      main.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return {
    isHeaderVisible,
    isHeaderTransparent
  };
};

export default useHeaderScroll;
