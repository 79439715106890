import { Fragment, useState } from "react";

import useRSVP from "custom-hooks/useRSVP";

import { BRAND_PRIMARY } from "constants/colors";

import LoadingSpinner from "./LoadingSpinner";

const RSVPModal = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [dietaryRestrictions, setDietaryRestrictions] = useState("any");
  const [otherDietaryRestrictions, setOtherDietaryRestrictions] =
    useState("None");
  // const [canCarpool, setIsCanCarpool] = useState("no");
  // const [canCarpoolNumber, setIsCanCarpoolNumber] = useState("0");
  const [response, setResponse] = useState("");

  const { isLoading, data, error, submitRSVP, resetData } = useRSVP();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    await submitRSVP({
      name,
      email,
      dietaryRestrictions,
      otherDietaryRestrictions,
      // canCarpool,
      // canCarpoolNumber,
      response
    });
    setName("");
    setDietaryRestrictions("any");
    setOtherDietaryRestrictions("-");
    // setIsCanCarpool("no");
    // setIsCanCarpoolNumber("0");
    setResponse("");
  };

  const handleClose = () => {
    setTimeout(() => {
      resetData();
      setName("");
      setResponse("");
    }, 1000);
  };

  return (
    <div
      className="modal fade"
      id="staticBackdrop"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex={-1}
      aria-labelledby="staticBackdropLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div
          className="modal-content"
          style={{
            backgroundColor: "#F9F0E5"
          }}
        >
          <form onSubmit={handleSubmit}>
            <div className="modal-header">
              <h5
                className="brand-text fw-bolder fs-3 modal-title"
                id="staticBackdropLabel"
              >
                RSVP
              </h5>
              <button
                type="button"
                className="btn-close fs-6"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={handleClose}
              />
            </div>
            <div className="p-3">
              <div
                className="card border-none p-2"
                style={{
                  color: "#222",
                  backgroundColor: "#F0BC92"
                }}
              >
                <small className="fs-6">
                  Please fill out form individually per person. You can use the
                  same email address for couples or families.
                </small>
              </div>
            </div>
            <div className="modal-body py-2">
              {isLoading ? (
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <p className="text-normal fw-bold">Sending your RSVP</p>
                  <LoadingSpinner color={BRAND_PRIMARY} />
                </div>
              ) : error ? (
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <p className="text-normal fw-bold">{error}</p>
                  <button
                    type="button"
                    className="py-1 fs-5 btn-primary"
                    onClick={resetData}
                  >
                    Retry
                  </button>
                </div>
              ) : data ? (
                <div className="d-flex flex-column align-items-center justify-content-center">
                  <p className="text-normal fw-bold mb-0">{data}</p>
                  <p className="text-normal fw-light fs-6">
                    You can now close this modal
                  </p>
                </div>
              ) : (
                <Fragment>
                  <label htmlFor="name">Name</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Please enter your name"
                      aria-label="Please enter your name"
                      required
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>

                  <label htmlFor="email">Email</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Please enter your email"
                      aria-label="Please enter your email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>

                  <label htmlFor="dietary-restrictions">
                    Do you have any dietary restrictions?
                  </label>
                  <div className="input-group mb-3">
                    <select
                      className="form-select"
                      id="dietary-restrictions"
                      name="dietary-restrictions"
                      value={dietaryRestrictions}
                      onChange={(e) => setDietaryRestrictions(e.target.value)}
                      required
                    >
                      <option value="any">Any</option>
                      <option value="vegan">Vegan</option>
                      <option value="vegetarian">Vegetarian</option>
                      <option value="pescatarian">Pescatarian</option>
                    </select>
                  </div>

                  <label htmlFor="name">Other dietary restrictions</label>
                  <div className="input-group mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Other dietary restrictions"
                      aria-label="Other dietary restrictions"
                      required
                      value={otherDietaryRestrictions}
                      onChange={(e) =>
                        setOtherDietaryRestrictions(e.target.value)
                      }
                    />
                  </div>

                  {/* <label htmlFor="dietary-restrictions">
                    Are you able to carpool?
                  </label>
                  <div className="input-group mb-3">
                    <select
                      className="form-select"
                      id="dietary-restrictions"
                      name="dietary-restrictions"
                      value={canCarpool}
                      onChange={(e) => setIsCanCarpool(e.target.value)}
                      required
                    >
                      <option value="no">No</option>
                      <option value="yes">Yes</option>
                    </select>
                  </div>

                  {canCarpool === "yes" && (
                    <Fragment>
                      <label htmlFor="name">
                        Great! How many people can you carpool?
                      </label>
                      <div className="input-group mb-3">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Other dietary restrictions"
                          aria-label="Other dietary restrictions"
                          required
                          value={canCarpoolNumber}
                          onChange={(e) =>
                            setIsCanCarpoolNumber(e.target.value)
                          }
                        />
                      </div>
                    </Fragment>
                  )} */}

                  <label htmlFor="response">Are you going?</label>
                  <div className="input-group mb-3">
                    <select
                      className="form-select"
                      id="response"
                      name="response"
                      value={response}
                      onChange={(e) => setResponse(e.target.value)}
                      required
                    >
                      <option value="">{"- select a response -"}</option>
                      <option value="yes">Yes</option>
                      <option value="no">No</option>
                      <option value="maybe">Maybe</option>
                    </select>
                  </div>
                </Fragment>
              )}
            </div>
            <div
              className="modal-footer"
              style={{
                borderTopColor: "#c2c2c2"
              }}
            >
              <button
                type="button"
                className="btn btn-secondary py-1 fs-5"
                style={{
                  borderRadius: "0px"
                }}
                data-bs-dismiss="modal"
                disabled={isLoading}
                onClick={handleClose}
              >
                Close
              </button>
              <button
                type="submit"
                className="py-1 fs-5 btn-primary"
                style={{
                  height: 40
                }}
                disabled={isLoading || !!data}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default RSVPModal;
