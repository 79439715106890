import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="bg-brand-primary">
      <div
        className="d-flex flex-column align-items-center justify-content-center"
        style={{
          minWidth: 200
        }}
      >
        <Link
          to="/"
          className="fs-2 brand-text mb-0 text-decoration-none text-white"
        >
          Ann & Miguel
        </Link>
        <button
          className="btn-tertiary brand-text mt-3"
          data-bs-toggle="modal"
          data-bs-target="#staticBackdrop"
        >
          <span>RSVP</span>
        </button>
      </div>
      <div className="d-flex flex-column align-items-center text-white brand-text px-5 text-center">
        <h4>
          Directly message us at{" "}
          <a
            href="mailto:annandmiguelswedding@gmail.com"
            className="text-decoration-none text-white"
          >
            annandmiguelswedding@gmail.com{" "}
          </a>
          for any questions!
        </h4>
      </div>
    </footer>
  );
};

export default Footer;
