import { useEffect } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import useAAMWStore from "store/useAAMWStore";

import Footer from "./Footer";
import Header from "./Header";
import RSVPModal from "./RSVPModal";

const Layout = () => {
  const navigate = useNavigate();
  const { isUserValidated, updateIsUserValidated } = useAAMWStore();

  useEffect(() => {
    if (!isUserValidated) {
      const secretValue = localStorage.getItem("aamv");

      if (process.env.NODE_ENV === "development") {
        updateIsUserValidated(true);
      } else if (secretValue) {
        const timePassed =
          new Date().getTime() - new Date(secretValue).getTime();
        const fifteenMinutes = 1000 * 60 * 15;
        if (timePassed < fifteenMinutes) {
          updateIsUserValidated(true);
        } else {
          navigate("/");
        }
      } else {
        navigate("/");
      }
    }
  }, []);

  return (
    <div className="main">
      <Header />
      <Outlet />
      <RSVPModal />
      {isUserValidated && <Footer />}
    </div>
  );
};

export default Layout;
