import { useState } from "react";

import { insertValues } from "utils/sheets";

const useRSVP = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState("");
  const [error, setError] = useState<string | null>(null);

  const submitRSVP = async (obj: Record<string, string>) => {
    setIsLoading(true);

    const res = await insertValues(obj);

    if (res.status === "error") {
      setError(res.message);
      setData("");
      setIsLoading(false);
      return;
    }

    setData(res.message);
    setError(null);
    setIsLoading(false);
  };

  const resetData = () => {
    setData("");
    setError(null);
  };

  return {
    isLoading,
    data,
    error,
    submitRSVP,
    resetData
  };
};

export default useRSVP;
