import { Link } from "react-router-dom";

import classNames from "classnames";
import useHeaderScroll from "custom-hooks/useHeaderScroll";

import useAAMWStore from "store/useAAMWStore";

const Header = () => {
  const { isUserValidated } = useAAMWStore();
  const { isHeaderVisible, isHeaderTransparent } = useHeaderScroll();

  return (
    <header
      className={classNames("d-flex py-4 p-2", {
        hidden: !isHeaderVisible,
        transparent: isHeaderTransparent
      })}
    >
      <div className="container d-flex align-items-center justify-content-between">
        <div className="d-flex">
          <Link
            to="/"
            className={classNames("fs-2 brand-text mb-0 text-decoration-none", {
              "text-white": isHeaderTransparent,
              "text-black": !isHeaderTransparent
            })}
          >
            Ann & Miguel
          </Link>
        </div>
        {isUserValidated && (
          <button
            className="btn-primary px-3 py-1 fs-6"
            data-bs-toggle="modal"
            data-bs-target="#staticBackdrop"
          >
            <span>RSVP</span>
          </button>
        )}
      </div>
    </header>
  );
};

export default Header;
