import { useRouteError } from "react-router-dom";

interface ErrorType {
  statusText: string;
  message: string;
}
const ErrorPage = () => {
  const error = useRouteError() as ErrorType;
  console.error(error);

  return (
    <div className="d-flex flex-column align-items-center justify-content-center vh-100">
      <h1>Oops!</h1>
      <p>Sorry, an unexpected error has occurred.</p>
      <p className="fst-italic">
        <i>{error.statusText || error.message}</i>
      </p>
    </div>
  );
};
export default ErrorPage;
